import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BannerComponent } from "./banner.component";
import { LocalStorage } from "@ng-idle/core";
import { AdvertisingController } from "src/app/core/controllers/advertising.controller";
import { AdvertisingDTO } from "src/app/core/interfaces/advertising";

@Injectable({
  providedIn: 'root',
})
export class BannerService {

  key = 'banner';

  constructor(private dialog: MatDialog, private localService: LocalStorage, private advertisingController: AdvertisingController) {
  }

  async open() {

    if (this.getWithExpiry()) {

      const item = await this.getAdvertising();
      if(item) this.openDialog(item)

    }

  }


  private openDialog = (data?: AdvertisingDTO) => this.dialog.open(BannerComponent, { data, disableClose: true });

  saveWithExpiry() {

    const ttl = 2 * 24 * 60 * 60 * 1000;
    const now = new Date();

    // Crea un oggetto con il valore e la scadenza
    const item = {
      value: true,
      expiry: now.getTime() + ttl,
    };

    this.localService.setItem(this.key, JSON.stringify(item));
  }

  getWithExpiry() {

    const itemStr = this.localService.getItem(this.key);

    // Se l'elemento non esiste, ritorna null
    if (!itemStr) {
      return true;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // Controlla se il dato è scaduto
    if (now.getTime() > item.expiry) {
      // Se è scaduto, rimuovilo e ritorna null
      this.localService.removeItem(this.key);
      return true;
    }

    return false;
  }

  async getAdvertising() {

    const items = await this.advertisingController.getList();
    if (items && items.Result && items.Result.length > 0) {

      return items.Result[0];

    }

    return null;

  }

}


